@use '../modules/quisi/dark-mode.scss';

.emoji {
  font-family: "Noto Color Emoji";
  pointer-events: none;
  user-select: none;
  vertical-align: bottom;

  @include dark-mode.dark-mode;
}
