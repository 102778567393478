.section {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 1rem;

  > * {
    // 3 columns with ~3% padding between them
    flex-basis: 30%;
  }
}
