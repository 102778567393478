.list {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(9rem, 1fr));
  list-style-type: none;
  margin: 0;
  padding: 0;

  > li {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1rem 2em;
    text-align: center;
  }
}
