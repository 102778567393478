.p {
  font-size: 1em;
  line-height: 1rem;
  margin: 0;
  padding: 0;
  text-indent: 1em;

  + .p {
    margin-top: 1rem;
  }
}
