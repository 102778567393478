.footer {
  padding-top: 1rem;
  text-align: right;
}

.section {
  box-sizing: border-box;
  margin-bottom: 1rem;
  margin-top: 1rem;
  max-height: 100%;
  max-width: 100%;
  padding-left: 2em;
  padding-right: 2em;
  position: relative;
  width: 100%;

  + .section {
    padding-top: 1rem;
  }
}
